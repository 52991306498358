import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-react-intl"
import { getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeaderColor from "../components/HeaderColor"
import HeaderCover from "../components/HeaderCover"
import Footer from "../components/Footer"
import { Row, Col, Tabs, Table, Space } from "antd"
import styled from "@emotion/styled"
import { Icon } from "@iconify/react"
import downloadAlt from "@iconify/icons-el/download-alt"
import chart from "../../static/assets/images/chart.png"

const PageContainer = styled.div`
  .title-tab {
    position: absolute;
    top: 80px;
    left: 30px;
    z-index: 999;
    font-weight: 400;
    font-size: 24px;
    color: #181818;
    font-family: "Kanit", sans-serif;
  }
  .ant-tabs {
    flex-wrap: wrap;
    padding: 60px 0;
    .ant-tabs-content-holder {
      margin-left: 0;
      border: none;
      padding: 0 5px;
      .ant-tabs-content {
        .ant-tabs-tabpane {
          padding-left: 0;
        }
      }
    }
  }
  .ant-tabs-nav {
    width: 100%;
    max-width: 100%;
    margin-bottom: 40px;
    background: #ffffff;
    box-shadow: 0px 4px 46px rgba(139, 139, 139, 0.08);
    border-radius: 20px;
    max-height: 310px;
    .ant-tabs-nav-wrap {
      padding: 30px 25px;
      padding-top: 70px;
      .ant-tabs-nav-list {
        .ant-tabs-ink-bar {
          display: none;
        }
        .ant-tabs-tab {
          margin-top: 15px;
          background: #ffffff;
          border: 1px solid #ededed;
          border-radius: 10px;
          padding: 15px 24px;
          &:first-of-type {
            margin-top: 0;
          }
          .ant-tabs-tab-btn {
            font-family: "Kanit", sans-serif;
            font-weight: 400;
            font-size: 16px;
            color: #7497b2;
          }
        }
        .ant-tabs-tab-active {
          background: #7497b2;
          border: 1px solid #f3f3f3;
          box-shadow: 0px 4px 47px rgba(0, 0, 0, 0.1);
          .ant-tabs-tab-btn {
            color: #fff;
          }
        }
      }
    }
  }
  .introWrapper {
    font-family: "Kanit", sans-serif;
    .title {
      font-weight: 400;
      font-size: 24px;
      color: #181818;
    }
    .content {
      font-weight: 400;
      font-size: 16px;
      color: #646464;
      p {
        font-weight: 400;
        font-size: 16px;
        color: #646464;
      }
    }
  }

  @media only screen and (min-width: 586px) {
    .ant-tabs {
      padding: 80px 45px;
    }
  }
  @media only screen and (min-width: 768px) {
    .ant-tabs {
      padding: 60px 15px;
    }
  }
  @media only screen and (min-width: 992px) {
    .title-tab {
      position: absolute;
      top: 100px;
      left: 60px;
      z-index: 999;
      font-weight: 400;
      font-size: 24px;
      color: #181818;
      font-family: "Kanit", sans-serif;
    }
    .ant-tabs {
      flex-wrap: nowrap;
      padding: 80px 30px;
      .ant-tabs-content-holder {
        padding: 0;
        margin-left: 10px;
        .ant-tabs-content {
          .ant-tabs-tabpane {
            padding-left: 25px;
          }
        }
      }
    }
    .ant-tabs-nav {
      max-width: 275px;
      max-height: 310px;
    }
  }
`

const TableContainer = styled.div`
  margin: 60px 0 90px 0;
  font-family: "Kanit", sans-serif;
  font-size: 16px;
  p {
    margin-bottom: 0.5em !important;
  }
  .ant-table-content {
    width: 100%;
    max-width: 100%;
    overflow-x: scroll;
    border-radius: 6px;
    border: 1px solid #ededed;
  }
  table {
    .ant-table-thead > tr > th {
      font-weight: 500;
      text-align: left;
      background: #4a789c;
      color: #fff;
      border-bottom: 1px solid #4a789c;
      font-size: 16px;
    }
    tr.ant-table-row {
      .ant-table-cell {
        font-weight: 400;
        font-size: 16px;
        color: #646464;
      }
    }
    .ant-space-item a {
      background: #fff;
      border-radius: 5px;
      padding: 5px 8px;
      border: 1px solid #7497b2;
      align-items: center;
      color: #7497b2;
      &:hover {
        color: #fff;
        background: #7497b2;
      }
    }
  }
  .ant-table-pagination-right {
    justify-content: center;
  }
  .ant-table-pagination.ant-pagination {
    margin: 20px 0;
  }
  li.ant-pagination-prev button.ant-pagination-item-link {
    justify-content: flex-start;
  }
  li.ant-pagination-next button.ant-pagination-item-link {
    justify-content: flex-end;
  }

  @media only screen and (min-width: 992px) {
    .ant-table-content {
      overflow-x: auto;
    }
  }
`

const TableInvestorContainer = styled.div`
  margin: 30px 0 0px 0;
  font-family: "Kanit", sans-serif;
  font-size: 16px;
  form ul {
    list-style-type: none;
    padding-inline-start: 0px;
    li:nth-child(2) {
      margin-left: 0px;
      margin-top: 10px;
    }
    label {
      color: #4a789c;
      font-size: 18px;
    }
    select {
      margin-top: 15px;
      background: #ffffff;
      border: 1px solid #7497b2;
      border-radius: 10px;
      padding: 10px 20px;
      padding-right: 80px;
    }
  }
  .ant-tabs {
    padding: 10px 0px;
  }
  .ant-tabs-nav {
    max-width: 100%;
    box-shadow: none !important;
    .ant-tabs-nav-list {
      width: 100%;
    }
    .ant-tabs-nav-wrap {
      padding: 30px 25px;
      .ant-tabs-nav-list {
        .ant-tabs-ink-bar {
          display: none;
        }
        .ant-tabs-tab {
          margin-top: 0px;
          background: #ffffff;
          border: 0px solid #ededed;
          border-radius: 0px;
          &:first-of-type {
            margin-top: 0;
          }
          .ant-tabs-tab-btn {
            font-family: "Kanit", sans-serif;
            font-weight: 400;
            font-size: 16px;
            color: #7497b2;
          }
        }
        .ant-tabs-tab-active {
          background: #7497b2;
          border: 0px solid #f3f3f3;
          box-shadow: 0px 4px 47px rgba(0, 0, 0, 0.1);
          .ant-tabs-tab-btn {
            color: #fff;
          }
        }
      }
    }
    .ant-tabs-tab {
      padding: 10px 8%;
      font-size: 14px;
      color: #181818;
      border-radius: none !important;
    }
  }
  .ant-tabs-tab-active {
    background: #fff !important;
    margin-top: none;
    box-shadow: none !important;
    border-bottom: 2px solid #4a789c !important;
    .ant-tabs-tab-btn {
      color: #4a789c !important;
    }
  }
  .ant-tabs-tab-btn {
    color: #000 !important;
  }
  p {
    margin-bottom: 0.5em !important;
  }
  .ant-table-content {
    width: 1200px;
    max-width: 100%;
    overflow-x: scroll;
    border-radius: 6px;
    border: 1px solid #ededed;
  }
  table {
    .ant-table-thead > tr > th {
      font-weight: 500;
      text-align: left;
      background: #4a789c;
      color: #fff;
      border-bottom: 1px solid #4a789c;
      font-size: 16px;
    }
    tr.ant-table-row {
      .ant-table-cell {
        font-weight: 400;
        font-size: 16px;
        color: #646464;
      }
    }
    .ant-table-tbody tr:nth-child(1) {
      background-color: #eff4ff;
      td {
        font-weight: 600;
      }
    }
    .ant-table-tbody tr:nth-child(4) {
      background-color: #eff4ff;
      td {
        font-weight: 600;
      }
    }
    .ant-space-item a {
      background: #fff;
      border-radius: 5px;
      padding: 5px 8px;
      border: 1px solid #7497b2;
      align-items: center;
      color: #7497b2;
      &:hover {
        color: #fff;
        background: #7497b2;
      }
    }
  }
  .ant-table-pagination-right {
    justify-content: center;
  }
  .ant-table-pagination.ant-pagination {
    margin: 20px 0;
  }
  li.ant-pagination-prev button.ant-pagination-item-link {
    justify-content: flex-start;
  }
  li.ant-pagination-next button.ant-pagination-item-link {
    justify-content: flex-end;
  }

  @media only screen and (min-width: 992px) {
    .ant-table-content {
      overflow-x: auto;
    }
    form ul {
      display: flex;
      li:nth-child(2) {
        margin-left: 100px;
        margin-top: 0px;
      }
      label {
        color: #4a789c;
        font-size: 18px;
      }
      select {
        margin-top: 15px;
        background: #ffffff;
        border: 1px solid #7497b2;
        border-radius: 10px;
        padding: 10px 20px;
        padding-right: 80px;
      }
    }
  }
`

const InvestorRelationsPage = ({ data, intl, location }) => {
  const [dataSourceInvestor, setDataSourceInvestor] = useState([])
  const [dataSourceDoc, setDataSourceDoc] = useState([])
  const [dataSourceInformation, setDataSourceInformation] = useState([])

  const locHash = location && location.hash ? location.hash : `#tab1`
  const [activeTab, setActiveTab] = useState(locHash)

  const locale = intl && intl.locale ? intl.locale : `th`
  const { pageFields, SourceInvestor, SourceDoc, SourceInformation } = data
  const {
    seoTitle,
    seoDescription,
    coverImage,
    shareholderInformation,
    shareholderNote,
    financialStatements,
    financialNote,
    annualReport,
    annualNote,
  } = pageFields.edges[0].node
  const getCoverImage = getImage(coverImage)

  useEffect(() => {
    if (dataSourceInvestor) {
      const investor = SourceInvestor.edges.map((item, index) => {
        return {
          key: index,
          name: item.node.shareholderName,
          dated: item.node.dateOfIssue,
          duedate: item.node.dueDate,
          age: item.node.debentureTermYear,
          interest: item.node.payDayInterest,
          rate: item.node.rateInterest,
          offer: item.node.typeOfferForSale,
          file: item.node.file.file.url,
        }
      })
      if (investor) {
        setDataSourceInvestor(investor)
      }
    }
  }, [SourceInvestor])

  useEffect(() => {
    if (dataSourceDoc) {
      const doc = SourceDoc.edges.map((item, index) => {
        return {
          key: index,
          name: item.node.title,
          tags: item.node.tag,
          file: item.node.file.file.url,
        }
      })
      if (doc) {
        setDataSourceDoc(doc)
      }
    }
  }, [SourceDoc])

  useEffect(() => {
    if (dataSourceInformation) {
      const information = SourceInformation.edges.map((item, index) => {
        return {
          key: index,
          name: item.node.incomeStatement,
          dated: item.node.millionBaht,
          duedate: item.node.renovated2561,
          age: item.node.renovated2562,
          interest: item.node.renovated2563,
        }
      })
      if (information) {
        setDataSourceInformation(information)
      }
    }
  }, [SourceInformation])

  const handleTabClick = (key, event) => {
    setActiveTab(key)
  }

  const columnsInvestor = [
    {
      title: () => {
        return <div>{intl.formatMessage({ id: "dataShareholder1" })}</div>
      },
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: () => {
        return <div>{intl.formatMessage({ id: "dataShareholder2" })}</div>
      },
      dataIndex: "dated",
      key: "dated",
      responsive: ["sm"],
    },
    {
      title: () => {
        return <div>{intl.formatMessage({ id: "dataShareholder3" })}</div>
      },
      dataIndex: "duedate",
      key: "duedate",
      responsive: ["sm"],
    },
    {
      title: () => {
        return <div>{intl.formatMessage({ id: "dataShareholder4" })}</div>
      },
      dataIndex: "age",
      key: "age",
      responsive: ["sm"],
    },
    {
      title: () => {
        return <div>{intl.formatMessage({ id: "dataShareholder5" })}</div>
      },
      dataIndex: "interest",
      key: "interest",
      responsive: ["sm"],
    },
    {
      title: () => {
        return <div>{intl.formatMessage({ id: "dataShareholder6" })}</div>
      },
      dataIndex: "rate",
      key: "rate",
      responsive: ["sm"],
    },
    {
      title: () => {
        return <div>{intl.formatMessage({ id: "dataShareholder7" })}</div>
      },
      dataIndex: "offer",
      key: "offer",
      responsive: ["sm"],
    },
    {
      title: () => {
        return <div>{intl.formatMessage({ id: "dataShareholder8" })}</div>
      },
      key: "action",
      width: "20%",
      render: (text, record) => (
        <Space size="middle">
          <a href={record.file} target="_blank" rel="noreferrer">
            <Icon
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                fontSize: "14px",
              }}
              icon={downloadAlt}
            />{" "}
            {intl.formatMessage({ id: "dataShareholder9" })}
          </a>
        </Space>
      ),
    },
  ]

  const columnsDoc = [
    {
      title: () => {
        return <div>{intl.formatMessage({ id: "dataDoc1" })}</div>
      },
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      dataIndex: "tags",
      key: "tags",
    },
    {
      title: () => {
        return <div>{intl.formatMessage({ id: "dataDoc2" })}</div>
      },
      key: "action",
      width: "20%",
      render: (text, record) => (
        <Space size="middle">
          <a href={record.file} target="_blank" rel="noreferrer">
            <Icon
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                fontSize: "14px",
              }}
              icon={downloadAlt}
            />{" "}
            {intl.formatMessage({ id: "dataDoc2" })}
          </a>
        </Space>
      ),
    },
  ]

  const { TabPane } = Tabs
  function callback(key) {
    console.log(key)
  }

  // const dataSourceInformation = [
  //     {
  //       key: '1',
  //       name: 'งบกำไรขาดทุน',
  //       dated: "(ล้านบาท)",
  //       duedate: '',
  //       age: '',
  //       interest: ''
  //     },
  //     {
  //       key: '2',
  //       name: 'รายได้จากการขายและการให้บริการ',
  //       dated: "",
  //       duedate: '2,336,155',
  //       age: '2,219,739',
  //       interest: '1,615,665'
  //     },
  //     {
  //       key: '3',
  //       name: 'รายได้จากการขายและการให้บริการ',
  //       dated: "",
  //       duedate: '2,336,155',
  //       age: '2,219,739',
  //       interest: '1,615,665'
  //     },
  //     {
  //       key: '4',
  //       name: 'งบดุล',
  //       dated: "(ล้านบาท)",
  //       duedate: '',
  //       age: '',
  //       interest: ''
  //     },
  //     {
  //       key: '5',
  //       name: 'สินทรัพย์รวม',
  //       dated: "",
  //       duedate: '2,336,155',
  //       age: '2,219,739',
  //       interest: '1,615,665'
  //     },
  //   ];

  const columnsInformation = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      dataIndex: "dated",
      key: "dated",
    },
    {
      title: () => {
        return <div>{intl.formatMessage({ id: "dataReport7" })}</div>
      },
      dataIndex: "duedate",
      key: "duedate",
    },
    {
      title: () => {
        return <div>{intl.formatMessage({ id: "dataReport8" })}</div>
      },
      dataIndex: "age",
      key: "age",
    },
    {
      title: "2563",
      dataIndex: "interest",
      key: "interest",
    },
  ]

  return (
    <Layout>
      <Seo lang={locale} title={seoTitle} description={seoDescription} />
      <HeaderColor />
      <HeaderCover
        bgImage={getCoverImage}
        coverText={intl.formatMessage({ id: "rootMenu1" })}
      />
      <PageContainer>
        <div className="gb-container">
          <Row>
            <Col span={24}>
              <h3 className="title-tab">
                {intl.formatMessage({ id: "rootMenu1" })}
              </h3>
              <Tabs
                tabPosition="left"
                activeKey={activeTab}
                onTabClick={handleTabClick}
              >
                <TabPane
                  tab={intl.formatMessage({ id: "subMenu11" })}
                  key="#tab1"
                >
                  <div className="introWrapper">
                    <h3 className="title">
                      {intl.formatMessage({ id: "subMenu11" })}
                    </h3>
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: shareholderInformation.shareholderInformation,
                      }}
                    />
                  </div>
                  <TableContainer>
                    <Table
                      dataSource={dataSourceInvestor}
                      columns={columnsInvestor}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: shareholderNote.shareholderNote,
                      }}
                    />
                  </TableContainer>
                </TabPane>
                <TabPane
                  tab={intl.formatMessage({ id: "subMenu12" })}
                  key="#tab2"
                >
                  <div className="introWrapper">
                    <h3 className="title">
                      {intl.formatMessage({ id: "subMenu12" })}
                    </h3>
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: financialStatements.financialStatements,
                      }}
                    />
                  </div>
                  <TableInvestorContainer>
                    <Layout>
                      <Row>
                        <Col>
                          <form>
                            <ul>
                              <li>
                                <label for="html">
                                  {intl.formatMessage({ id: "dataReport1" })}
                                </label>
                                <br />
                                <select id="investor" name="investor">
                                  <option value="datainvestor-1">
                                    {intl.formatMessage({ id: "dataReport3" })}
                                  </option>
                                  <option value="datainvestor-2">
                                    {intl.formatMessage({ id: "dataReport3" })}
                                  </option>
                                  <option value="datainvestor-3">
                                    {intl.formatMessage({ id: "dataReport3" })}
                                  </option>
                                </select>
                              </li>
                              <li>
                                <label for="html">
                                  {intl.formatMessage({ id: "dataReport2" })}
                                </label>
                                <br />
                                <select
                                  id="investordetail"
                                  name="investordetail"
                                >
                                  <option value="datainvestordetail-1">
                                    {intl.formatMessage({ id: "dataReport3" })}
                                  </option>
                                  <option value="datainvestordetail-2">
                                    {intl.formatMessage({ id: "dataReport3" })}
                                  </option>
                                  <option value="datainvestordetail-3">
                                    {intl.formatMessage({ id: "dataReport3" })}
                                  </option>
                                </select>
                              </li>
                            </ul>
                          </form>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane
                              tab={intl.formatMessage({ id: "dataReport4" })}
                              key="1"
                            >
                              <img src={chart} width="100%" alt="chart1" />
                            </TabPane>
                            <TabPane tab="EBITDA" key="2">
                              <img src={chart} width="100%" alt="chart2" />
                            </TabPane>
                            <TabPane
                              tab={intl.formatMessage({ id: "dataReport5" })}
                              key="3"
                            >
                              <img src={chart} width="100%" alt="chart3" />
                            </TabPane>
                            <TabPane
                              tab={intl.formatMessage({ id: "dataReport6" })}
                              key="4"
                            >
                              <img src={chart} width="100%" alt="chart4" />
                            </TabPane>
                          </Tabs>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Table
                            dataSource={dataSourceInformation}
                            columns={columnsInformation}
                          />
                        </Col>
                      </Row>
                    </Layout>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: financialNote.financialNote,
                      }}
                    />
                  </TableInvestorContainer>
                </TabPane>
                <TabPane
                  tab={intl.formatMessage({ id: "subMenu13" })}
                  key="#tab3"
                >
                  <div className="introWrapper">
                    <h3 className="title">
                      {intl.formatMessage({ id: "subMenu13" })}
                    </h3>
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: annualReport.annualReport,
                      }}
                    />
                  </div>
                  <TableContainer>
                    <Table dataSource={dataSourceDoc} columns={columnsDoc} />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: annualNote.annualNote,
                      }}
                    />
                  </TableContainer>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </PageContainer>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query InvestorRelationsPageQuery($locale: String) {
    pageFields: allContentfulInvestorRelationsPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          seoTitle
          seoDescription
          coverImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          shareholderInformation {
            shareholderInformation
          }
          shareholderNote {
            shareholderNote
          }
          financialStatements {
            financialStatements
          }
          financialNote {
            financialNote
          }
          annualReport {
            annualReport
          }
          annualNote {
            annualNote
          }
        }
      }
    }
    SourceInvestor: allContentfulDataSourceInvestor(
      filter: { node_locale: { eq: $locale } }
      sort: { order: ASC, fields: createdAt }
    ) {
      edges {
        node {
          shareholderName
          dateOfIssue
          dueDate
          debentureTermYear
          payDayInterest
          rateInterest
          typeOfferForSale
          file {
            file {
              url
            }
          }
        }
      }
    }
    SourceDoc: allContentfulDataSourceDoc(
      filter: { node_locale: { eq: $locale } }
      sort: { order: ASC, fields: createdAt }
    ) {
      edges {
        node {
          title
          tag
          file {
            file {
              url
            }
          }
        }
      }
    }
    SourceInformation: allContentfulDataSourceInformation(
      filter: { node_locale: { eq: $locale } }
      sort: { order: ASC, fields: createdAt }
    ) {
      edges {
        node {
          incomeStatement
          millionBaht
          renovated2561
          renovated2562
          renovated2563
        }
      }
    }
  }
`

export default injectIntl(InvestorRelationsPage)
